import { useMutation } from '@apollo/client';
import { CHECK_SESSION } from '../mutations/confidentiality';
import useHeaders from '../hooks/useHeaders';

export const useCheckSession = () => {
  const [checkSession] = useMutation(CHECK_SESSION, {
    context: {
      headers: useHeaders()
    }
  });

  return async () => {
    try {
      const { data } = await checkSession();
      return {
        data: data.checkSession
      };
    } catch (error) {
      return {
        error
      };
    }
  };
};
