import { useEffect, useState } from 'react';

const useShouldBlockAccess = (isEmbed, privacyType, restrictedDomains = []) => {
  const [isFromIframe, setIsFromIframe] = useState(false);
  const [shouldBlockAccess, setShouldBlockAccess] = useState(false);

  useEffect(() => {
    if (window.location !== window.parent.location) {
      setIsFromIframe(true);
    }
  }, []);

  useEffect(() => {
    if (isFromIframe && (!isEmbed || privacyType === 'private')) {
      setShouldBlockAccess(true);
    }
  }, [isEmbed, isFromIframe, privacyType]);

  useEffect(() => {
    if (!restrictedDomains.length || !isFromIframe || privacyType !== 'custom')
      return;

    const parentUrl = document.location.ancestorOrigins[0];
    if (parentUrl) {
      const hostname = new URL(parentUrl).hostname;
      const domain = hostname.split('.').slice(-2).join('.');
      const isDomainRestricted = restrictedDomains.includes(domain);
      if (isDomainRestricted) {
        setShouldBlockAccess(false);
      } else {
        setShouldBlockAccess(true);
      }
    } else {
      setShouldBlockAccess(true);
    }
  }, [isFromIframe, restrictedDomains]);

  return shouldBlockAccess;
};

export default useShouldBlockAccess;
