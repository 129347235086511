import { gql } from '@apollo/client'; // eslint-disable-line import/no-extraneous-dependencies

export const GET_UPDATED_LIVE_PLAYLIST = gql`
  subscription playlistLive($playlistId: ID!) {
    playlistLive(playlistId: $playlistId) {
      id
      startDate
      shouldShowCountdown
      status
      title
      description
      liveUrl
      hasReplay
      hasDVR
      replay {
        id
        isEncodingDone
      }
    }
  }
`;
