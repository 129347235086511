import { gql } from '@apollo/client'; // eslint-disable-line import/no-extraneous-dependencies

export const GET_UPDATED_LIVE = gql`
  subscription getUpdatedLiveChapters($id: ID!) {
    live(id: $id) {
      id
      startDate
      shouldShowCountdown
      status
      title
      description
      liveUrl
      hasReplay
      hasDVR
      replay {
        id
        isEncodingDone
      }
    }
  }
`;
