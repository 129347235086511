import Head from 'next/head';
import { getContent, getHeadInfos } from '../../utils/functions/contentParams';
// import { getPlayerInfo } from '../../utils/functions/player';

// TODO investigate why the head data are not in the source code of the page
const HeadSEO = function ({ media, live, playlist, locale }) {
  const { content, contentType, isLive } = getContent(media, live, playlist);
  const { title, image, description } =
    content && getHeadInfos(content, isLive);
  // const { sourceRaw } = content && getPlayerInfo(media, live);
  return (
    <Head>
      <title>{title}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
      />
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      {/* {sourceRaw && <meta property="og:video" content={sourceRaw} />} */}
      <meta
        property="og:url"
        content={`${process.env.NEXT_PUBLIC_PLAYER_URL}/${contentType}/${content.id}`}
      />
      {playlist && (
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css"
          rel="stylesheet"
        />
      )}
      <meta property="og:locale" content={locale} />
      {/* FIXME: remove after using next-image
      {isLive ? (
        <link
          rel="preload"
          href={`/api/proxy-image?url=${encodeURIComponent(image)}`}
          as="image"
        />
      ) : (
        <link rel="preload" href={image} as="image" /> */}
      {/* )} */}
    </Head>
  );
};

export default HeadSEO;
