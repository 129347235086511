import { atom, useRecoilValue } from 'recoil';

export const notificationShouldUpdateState = atom({
  key: 'notificationShouldUpdateState',
  default: false
});

export const GetNotificationSouldUpdateFromRecoil = () => {
  const notificationShouldUpdate = useRecoilValue(
    notificationShouldUpdateState
  );
  return notificationShouldUpdate;
};
