import { Modal } from 'antd';
import Cookies from 'js-cookie';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { BiCookie } from 'react-icons/bi';
import { useRecoilState } from 'recoil';
import { rgpdModalState } from '../../recoil/rgpd';

const RGPDModal = () => {
  const refCheckbox = useRef(null);
  const [isModalVisible, setIsModalVisible] = useRecoilState(rgpdModalState);

  const { t } = useTranslation("common");

  function setOptOutText(checkbox) {
    const _paq = (window._paq = window._paq || []);
    _paq.push([
      function () {
        checkbox.checked = !this.isUserOptedOut();
        // label.innerText = this.isUserOptedOut()
        //   ? 'Vous êtes actuellement désabonné. Cliquez ici pour vous inscrire.'
        //   : 'Vous êtes actuellement inscrit. Cliquez ici pour vous désinscrire.';
      }
    ]);
  }

  const handleCheckboxUUIDChange = (e) => {
    const checked = e.target.checked;
    Cookies.set('rgpd-uuid', checked, { expires: 30 });
  };

  const handleCheckboxMatomoChange = (e) => {
    if (!refCheckbox.current) return;
    const checked = e.target.checked;
    const _paq = (window._paq = window._paq || []);
    if (checked) {
      _paq.push(['forgetUserOptOut']);
    } else {
      _paq.push(['optUserOut']);
    }
    setOptOutText(refCheckbox.current);
  };

  useEffect(() => {
    if (!isModalVisible) return;
    if (!refCheckbox.current) return;
    setOptOutText(refCheckbox.current);
  }, [isModalVisible, refCheckbox]);

  return (
    <Modal
      title={<><BiCookie size={32} />{t("rgpd-modal")}</>}
      open={isModalVisible}
      width={800}
      footer={null}
      closable={true}
      onCancel={() => setIsModalVisible(false)}
      
    >
      <div data-cy="rgpd-modal">
      {/* <h2
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {t("rgdp-title")}
        
      </h2> */}
      <p>
        {t("rgdp-description")}
        {t("rgdp-consequences")}
      </p>
      <p>
        <label >
          <input
            type="checkbox"
            defaultChecked={Cookies.get('rgpd-uuid') === 'true'}
            data-cy="rgpd-uuid-checkbox"
            onChange={handleCheckboxUUIDChange}
          />
          {t("rgdp-deactivate-uuid")}
        </label>
      </p>
      <p>
        <label >
          <input
            type="checkbox"
            id="output"
            ref={refCheckbox}
            data-cy="rgpd-matomo-checkbox"
            onChange={handleCheckboxMatomoChange}
          />
            {t("rgdp-deactivate-matomo")}
        </label>
      </p>
      </div>
      <style jsx>{`
        label {
          display: flex;
          align-items: center;
        }
        input[type='checkbox'] {
          margin-right: 8px;
        }
      `}</style>
      <style global jsx>{`
        .ant-modal {
          top: 10%;
        }
        .ant-modal-title {
            display: flex;
            align-items: center;
            font-size: 24px;
        }
        .ant-modal-title svg {
            margin-right: 8px;
        }
        `}</style>
        
    </Modal>
  );
};

export default RGPDModal;
