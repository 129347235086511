export const loadMatomo = () => {
  //if (process.env.NODE_ENV === 'production') {
    const _paq = (window._paq = window._paq || []);
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    const u = 'https://streamfizz.matomo.cloud/';
    _paq.push(['setTrackerUrl', `${u}matomo.php`]);
    _paq.push(['setSiteId', '1']);
    console.log('_paq', _paq)
    const d = document;
    const g = d.createElement('script');
    const s = d.getElementsByTagName('script')[0];
    
    g.type = 'text/javascript';
    g.async = true;
    g.src = '//cdn.matomo.cloud/streamfizz.matomo.cloud/matomo.js';
    s.parentNode.insertBefore(g, s);
  //}
};
