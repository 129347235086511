import CustomError from '../components/CustomError';

export default function Custom404({}) {
  return (
    <CustomError
      errorCode={'Erreur 404'}
      errorMessage={"Il semblerait que cette page n'existe pas."}
    />
  );
}
