import { atom, useRecoilValue } from 'recoil';

export const notificationCountState = atom({
  key: 'notificationCountState',
  default: 0
});

export const GetNotificationCountFromRecoil = () => {
  const notificationCount = useRecoilValue(notificationCountState);
  return notificationCount;
};
